.Article {
    margin: 1rem 0;

    @include media-query($breakpoint-l) {
        margin: 3rem 0;
    }
}

.Article-title {
    font-size: 2em;
    margin-bottom: 0;
    margin-top: 0 !important;

    .postid-4752 &,
    .postid-4751 &,
    .postid-4694 & {
        text-align: center;
    }

}

.Article-meta {
    margin-bottom: 2em;
    font-size: 0.8em;
    color: $color-gray;
}

.Article-figure {
    margin-bottom: 2em;
}

.Article-share {
    margin-top: 3rem;

    .sfsi_shortcode_container {
        float: none;
    }
}

.Page-content,
.Article-content {

    h4 {
        margin-bottom: 0.25em;
        // color: $color-gray-dark;
        margin-top: 0;
    }

    p {
        margin-bottom: 1em;
        font-size: 1rem;
        line-height: 2em;
    }

    .wp-block-image {
        margin: 0;
    }

    .wp-block-separator {
        max-width: 14rem;
        margin-left: auto;
        border-bottom: 1px solid #ccc;
        margin-right: auto;
    }

    .wp-block-button__link {
        background: white;
        color: $color-gray-dark;
        padding: 0.7rem 2rem;

        &:hover {
            text-decoration: underline;
        }
    }

    .wp-block-columns {
        margin-top: 3rem;
    }

    .aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
    }   

    .alignleft,
    .left {
            margin-right: 1em;
            margin-bottom: 0.35em;
            margin-top: 0.35em;
            float: left;
    }

    .alignright,
    .right {
            margin-left: 1em;
            margin-bottom: 0.35em;
            margin-top: 0.35em;
            float: right;
    }

    blockquote {
        font-size: 1.5em;
        font-weight: 600;
        text-transform: lowercase;
        border-left: 2px solid black;
        padding-left: 1em;
    }
}
