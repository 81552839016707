.Header {
    padding: 1.3rem 0;
    width: 100%;
    position: fixed;
    // top: 0;
    border-bottom: 1px solid #000;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
    background-color: $color-black;
    color: $color-white;
    z-index: 22;
    //display: none;
    height: 6rem;
}

.Header-left {
    //z-index: 3;
}


.Header-logo {
    position: relative;
    //top: 1em;
    //left: 1em;
    text-decoration: none;
    display: flex;
    align-items: center;
    //z-index: 2;

    font-family: $font-logo;

    //.u-marginLs {
    //    display: none;
    //
    //    @include media-query($breakpoint-l) {
    //       display: block;
    //    }
    //}

    h1 {
        font-size: 1.6em !important;
        font-family: $font-logo;

        @include media-query($breakpoint-l) {
            font-size: 2em !important;
        }
    }

    p {
        font-size: 0.6em !important;
        //line-height: 1;

        @include media-query($breakpoint-l) {
            font-size: 0.82em !important;
        }
    }
}

.Header-container {
    display: flex;
    align-items: center;
    z-index: 2;
    position: sticky;
    padding: 0 1em;
}

.Header-title {
    margin: 0;
    margin-bottom: -0.25em;
}

.Header-firstname {
    font-weight: 600;
}

.Header-lastname {
    font-weight: 300;
}

.Header-tagline {
    margin: 0;
}
