.Hero {
    // min-height: 40em;
    min-height: 100vh;
    overflow: hidden;
    position: relative; 
    background-color: $color-black;
    color: $color-white;
    z-index: 2;
    border-bottom: 1px solid #000;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
    margin-top: 6rem;
    //position: absolute;
    //width: 100%;
    //height: 100%;
}

.Hero--small {
    min-height: 25rem;
    @include media-query($breakpoint-l) {
        min-height: 30rem;
    }


    .Hero-content {
        min-height: 25rem;
        @include media-query($breakpoint-l) {
            min-height: 30rem;
        }
    }
}

.Hero-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: $color-white;
    position: relative;
    text-align: left;
    width: 100%;
    min-height: 100vh;
}

.Hero-container {
    width: 100%;
}

.Hero-logo {
    position: absolute;
    top: 1em;
    left: 1em;
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    z-index: 2;

    @include media-query($breakpoint-xl) {
        top: 2em;
        left: 2em;
    }
}

.Hero-container--smaller {
    
	@include media-query($breakpoint-xl) {
        padding-right: 25em;
        width: 100%;
	}
}

.Hero-figure {
    padding: 0;
    margin: 0;
    height: 0;
    overflow: hidden;

    > img,
    .Hero-figureImage {
        transform: scale(1.0);
        overflow: hidden;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .3s ease-in-out all;
    }
}

