.Media {
	align-items: flex-start;
	display: flex;
	margin-bottom: 1em;
}

.Media-content {
	flex: 1;
}

.Media-image {
	margin-right: 1em;

	&:empty {
		display: none;
	}
}
