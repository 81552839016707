
.Card {
    position: relative;
    text-decoration: none;
    margin-bottom: 1rem;
    height: 100%;
    //padding-bottom: 6rem;
    background: #333;
    border-radius: 7px;
    overflow: hidden;

}

.Card--hover {
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {

        .Card-figureImage {
            filter: grayscale(0%) contrast(100%);
            transform: translate(-50%, -50%) scale(1.2);
        }
    }
}

.Card-figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    overflow: hidden;
}

.Card-figureImage {
    min-height: 100%;
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%) scale(1);
    object-fit: cover;
    transition: ease-in-out all .3s;
}

.Card-content {
    padding: 0.25rem 2rem;
    transition: all 0.3s ease-in-out;
}

.Card-title {
    //text-transform: lowercase;
    margin-bottom: 1rem;
}

.Card-meta {
    font-size: 0.8em;
    margin-bottom: 1em;
    color: $color-gray;
}

.Card-intro {
    font-size: 0.9em;
}

.Card-button {
    position: absolute;
    bottom: 2rem;
}

.Card--project {

}

.Card--home {
    //height: 40vh;

    .Card-figure {
        //height: 40vh;
        padding-bottom: 60%;

        @include media-query($breakpoint-l) {
            padding-bottom: 80%;
        }
        @include media-query($breakpoint-xl) {
            padding-bottom: 110%;
        }
    }

    .Card-figureImage {
        filter: grayscale(100%) contrast(125%);
        transition: all 0.3s ease-in-out;
    }

    .Card-content {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: rgba($color: #000000, $alpha: 0.25);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: .25rem 1.5rem;
    }

    .Card-hover {
        background-color: rgba($color: #000000, $alpha: 0);
    }

    .Card-title {
        font-size: 1.4rem;
        width: 100%;
        text-shadow: 0px 1px 2px black;
    }

    //@include media-query($breakpoint-xl) {
    //    height: 80vh;
    //
    //    .Card-figure {
    //        height: 80vh;
    //    }
    //
    //    .Card-content {
    //        height: 80vh;
    //    }
    //}
}