
// .master-slider-parent {
//     height: 750px;
//     overflow: hidden;
// }

// .ms-parent-id-1 > .master-slider {
// 	background: #f5f5f5;
//     // min-height: 240px;
//     // overflow: hidden;
// }

// .ms-slide-bgcont {
//     height: 100vh !important;
// }
// .ms-slide {
//     min-height: 100vh !important;
//     height: 1px !important;
// }
// .ms-slide-container,
// .ms-view,
// .ms-container,
// .ms-inner-controls-cont {
//     height: 100vh !important;
// }

.ms-slide .ms-slide-bgcont img {
    // height: auto;
    // min-width: 1200px;
	// width: 100% !important;
    // min-height: 100vh;
	// height: 1px !important;
	// position: absolute;
	// top: 50%;
	// left: 50%;
    // transform: translate(-50%, -50%) !important;;
    // -webkit-transform: translate(-50%, -50%) !important;;
    // margin-left: 0 !important;
}

.ms-bullet {
    border: 1px solid $color-white !important;
    background: none !important;
    width: 0.65em !important;
    height: 0.65em !important;
    border-radius: 4em;
}

.ms-bullet-selected {
    background-color: $color-white !important;
}

.ms-bullets.ms-dir-h {
	width: 100px !important;
}

.ms-inner-controls-cont {
    max-width: 100%;
}

.ms-info {
    font-size: 2.25em;
    font-weight: 600;
    line-height: 1.25em;
    
    p {
        text-align: right !important;
    }
}

.ms-slide-info {
    display: none;
    right: 3em !important;
    top: 5em !important;

    @include media-query($breakpoint-xl) {
        display: block;
    }
}

// Custom logo every slide
.Masterslider {
    position: relative;
    z-index: 2;
    color: $color-black;
    border-bottom: 1px solid #000;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
    margin-top: 6rem;
}

.Masterslider-logo {
    position: absolute;
    top: 1em;
    left: 1em;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    z-index: 22;

    img {
        width: 50px;
        height: 50px;
        margin-right: 0.5em;
    }

    @include media-query($breakpoint-xl) {
        display: block;    
        top: 2em;
        left: 2em;

        img {
            width: 200px;
            height: 200px;
            margin-right: inherit;
        }
    }
}

.Masterslider-continue {
    position: absolute;
    cursor: pointer;
    text-align: center;
    z-index: 22;
    bottom: 4rem;
    right: 2rem;
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    width: fit-content;
    text-shadow: 0 1px 2px #000;
    font-weight: bold;

    i {
        text-shadow: 0 1px 2px #000;
    }

    @include media-query($breakpoint-xl) {
        bottom: 5rem;
        right: 6rem;
        font-size: 1.5rem;
    }
}
