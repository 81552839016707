.u-imageBlur {
    filter: blur(100px);
}

.u-imageGray {
    filter: grayscale(100%);
}

.u-imageBlurGray {
    filter: blur(30px) grayscale(100%);
}