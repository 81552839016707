$breakpoint-m:			30em; //  480px
$breakpoint-ml:         35em;
$breakpoint-l:			40em; //  640px
$breakpoint-xl:			55em; //  880px
$breakpoint-xxl:		60em; //  960px
$breakpoint-xxxl:		80em; // 1280px

// Fonts
$font-fallback-stack: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-heading: 'Fraunces', sans-serif, Helvetica, Arial, sans-serif;
$font-default: 'Lexend', sans-serif, Helvetica, Arial, sans-serif;
$font-logo: 'Montserrat', sans-serif, Helvetica, Arial, sans-serif;
$font-special: 'ace_sansdemo', sans-serif, Helvetica, Arial, sans-serif;

// Colors
$color-gray-light:      #e4e4e4;
$color-gray:            #676767;
$color-gray-dark:       #333333;
$color-black:           #252525;
$color-white:           #FFFFFF;

// Colors
$color-primary:		    #ee7544;
$color-primary-dark:     #c35325;
$color-blue:            #343980;
$color-blue-light:      #4990c8;

// Specials
$border-radius: 7px;
$border-radius-round: 6em;
$box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.1);