@font-face {
	font-family: 'ace_sansdemo';
	src: url('assets/fonts/acesans-demo-webfont.woff2') format('woff2'),
	url('assets/fonts/acesans-demo-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

//@font-face {
//	font-family: 'Savoye';
//	src: url('assets/fonts/SavoyeLetPlain.woff2') format('woff2'),
//	url('assets/fonts/SavoyeLetPlain.woff') format('woff');
//	font-weight: normal;
//	font-style: normal;
//
//}

html {
	font-size: 100%; // 18px
}

body {
	line-height: 1.75;
	font-family: $font-default;
	font-weight: 400;
}


h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	line-height: 1.25;
	margin: 0.5em 0;
	font-family: $font-heading;
	font-weight: 600;
	//text-transform: lowercase;
}

h1,
.h1 {
	font-size: 1.75rem;
}

h2,
.h2 {
	font-size: 1.5rem;
}

h3,
.h3 {
	font-size: 1.25rem;
	margin: 1em 0 0.5em;
}

h4,
.h4 {
	font-size: 1rem;
	margin: 1em 0 0.5em;
}
