// Flexible media embeds
//
// For use with media embeds – such as videos, slideshows, or even images –
// that need to retain a specific aspect ratio but adapt to the width of their
// containing element.
//
// Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
// Source: https://github.com/suitcss/components-flex-embed
.wp-block-embed-youtube,
.FlexEmbed {
	display: block;
	margin: 0 0 0 0;
	overflow: hidden;
	position: relative;
	background: black;
}

.FlexEmbed--noSpace {
	margin: 0;
}

// The aspect-ratio is applied to an empty element because it allows
// the component to respect `max-height`. Default aspect ratio is 1:1.
.FlexEmbed-ratio {
	display: block;
	padding-bottom: 100%;
	width: 100%;
}

// A custom ratio can be applied using an inline padding-bottom style

// Modifier: 16:9 aspect ratio
.FlexEmbed-ratio--16by9 {
	padding-bottom: 56%;
}

.wp-block-embed-youtube {
	display: block;
	//padding-bottom: 100%;
	width: 100%;
	//padding-bottom: 56%;
}

// Fit the content to the aspect ratio
.wp-block-embed__wrapper,
.FlexEmbed-content {
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.wp-block-embed__wrapper iframe,
.FlexEmbed-content iframe {
	border: 0;
	height: 100%;
	width: 100%;
}

.FlexEmbed-content div {
	height: 100%;
}

.youtube-container {
	overflow: hidden;
	width: 100%;
	/* Keep it the right aspect-ratio */
	aspect-ratio: 16/9;
	/* No clicking/hover effects */
    //pointer-events: none;
}

.youtube-container iframe {
	/* Extend it beyond the viewport... */
	width: 300%;
	height: 100%;
	/* ...and bring it back again */
	margin-left: -100%;
}