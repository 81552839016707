.Breadcrumbs {
    color: $color-gray-light;
    text-transform: uppercase;
    font-size: 0.75em;
    margin-left: -1em;

    a,
    .breadcrumb_last {
        margin-right: 1em;
        margin-left: 1em;
    }

    a {
        text-decoration: none;

        &:hover {
            color: $color-primary;
        }

    }
}

.home .Breadcrumbs-container {
    display: none;
}

.home .u-marginVxl {
    display: none;
}

.wp-image-260 {
    width: 150px;

    @include media-query($breakpoint-l) { 
        width: 225px;
    }
}