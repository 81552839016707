.norm_row {
    text-align: center !important;
    width: inherit !important;
    position: inherit !important;
    float: inherit !important;
}

.grecaptcha-badge {
    z-index: 2;
}

.wpcf7 label {
    font-size: 0.8rem;
}

.wp-block-embed__wrapper {
    iframe {
        min-height: 400px;
    }
}