.Paging,
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 4rem;
}

.Paging-item,
.page-numbers {
    color: $color-white;
    border: 1px solid $color-black;
    padding: 0.9em 1.25em;
    min-width: 2.5em;
    cursor: pointer;
    border-radius: $border-radius-round;
    background-color: $color-gray;
    text-align: center;
    text-decoration: none;
    margin-right: 0.5em;

    &:active,
    &:hover,
    &:focus {
        background-color: $color-gray;
        color: $color-white;
    }
}

.Paging-current,
.page-numbers.current {
    background-color: $color-gray;
    color: $color-white;
}

.screen-reader-text {
    display: none;
}