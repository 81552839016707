.Page {
    margin: 1rem 0;

    @include media-query($breakpoint-l) {
        margin: 3rem 0;
    }
}

.Page-title {
    // text-align: center;
    // font-size: 2.5em;
    margin-top: 0 !important;
}

.Page-content {
    .wp-block-gallery {
        margin: 0;
    }

    .page-template-page-small & {

        p {
            text-align: justify;
        }
    }
}

.page-template-ck-page-staggering {
    background: black;
    overflow: hidden;

    footer,
    #menu-cv-menu-nl {
        //display: none;
    }
}

.font-ace .Container {
    margin-bottom: 4rem;

    @media (max-width: 781px) {
        margin-top: 0;
    }

    h1, h3, h2, p, li {
        font-family: $font-special;
        color: #FEE19A;
    }

    h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
        @media (max-width: 781px) {
            text-align: center;
            font-size: 2rem;
        }
    }

    h2 {
        font-size: 1.75rem;
        margin-bottom: 2rem;
        margin-top: 0;
        @media (max-width: 781px) {
            text-align: center;
            font-size: 1.5rem;
        }
    }

    h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        margin-top: 3rem;

        &:after {
            content: '';
            height: 1px;
            width: 50%;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            line-height: 1.2;
            margin-bottom: 0.75rem;
            text-align: center;
        }
    }

    p {
        font-size: 1.1rem;
    }

    p.has-text-align-left { // intro
        text-align: center;
        font-size: 1.3rem;
    }

    li,
    p.has-text-align-center {
        font-size: 1.6rem;
        line-height: 1.2;
    }

    @media (max-width: 781px) {
        img.wp-image-4473,
        img.wp-image-4456 {
            width: 250px;
        }
    }

    p {
        margin-bottom: 1rem;
    }

    h3.inspired {
        margin-top: 0;
    }
    p.inspired {
        margin-bottom: 0;
    }
    .wp-container-13 { //synopsis
        @media (max-width: 781px) {
            gap: 0;
            margin-top: -2rem;
        }

    }
    .wp-block-separator {
        border-bottom: 0;
        border-color: #FEE19A;
        margin: 3rem auto;
        width: 10rem;
    }

    .wp-block-image .alignright {
        margin: 0.5em 0 0.5em 1em;
    }

    .wp-block-image .alignleft {
        margin: 0.5em 1em .5em 0;
    }

    .wp-block-gallery {
        margin-bottom: 6rem;
    }

    .wp-block-cb-carousel {
        margin-bottom: 8rem;
    }
}

.slick-dots {
    bottom: -3rem !important;

    li button:before {
        color: #FEE19A !important;
    }
}

.wp-block-cb-carousel .slick-next:before,
.wp-block-cb-carousel .slick-prev:before {
    //color: #FEE19A !important;
}