/**
 * Lays out cells in a row, with automatically row wrapping.
 *
 * See: https://github.com/suitcss/components-grid/blob/master/lib/grid.css
 * See: http://philipwalton.github.io/solved-by-flexbox/demos/grids/
 */

// Grid container, should only have Grid-cells as children
.Grid {
	display: flex;
	flex-wrap: wrap;
	// Compensate for the gutter
	margin-left: -1em;
}

.Grid-cell {
	// Cells are equal height by default
	display: flex;
	// Prepare cell content to take up all available space within a cell
	flex-direction: column;
	// This creates a gutter between cells
	padding-left: 1em;
	// By default, move cells below each other. Using utility classes it is
	// possible to set the cell sizes (per breakpoint), in order to display them
	// next to each other.
	width: 100%;
}

// Modifier: horizontally center a grid cell
.Grid-cell--center {
	margin: 0 auto;
}

// Modifier: vertically align a grid cell to the bottom
.Grid-cell--bottom {
	align-self: flex-end;
}

.Grid--bigGap {
	margin-left: -2em;

	> .Grid-cell {
		padding-left: 2em;
	}
}

.Grid--noGap {
	margin-left: -0em;

	> .Grid-cell {
		padding-left: 0em;
	}
}

.Grid--1px {
	margin-left: -1px;

	> .Grid-cell {
		padding-left: 1px;
	}
}
