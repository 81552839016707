.TeaserCustom {
    display: flex;
    align-items: center;
}

.TeaserCustom-figure {
    margin-bottom: -4em;
    margin-top: -4em;
    width: 100%;
}

.TeaserCustom-content {
    margin-left: 3em;
}

.TeaserCustom-contentTitle {
    font-size: 2em;
    color: $color-gray-dark;
    font-weight: 800;
}

.TeaserCustom-contentText {
    font-size: 1.3em;
}
