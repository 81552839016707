
.calendar-event-title,
.calendar-event {
    color: $color-white !important;
}

// dt {
//     font-weight: bold;
//     display: inline;
// }

// dd {
//     margin: 0;
// }

// .tribe-events-back {
//     display: none;
// }

// // LISt
// .tribe-list-widget {
//     // Remove bullets
// 	list-style: none;
// 	margin-bottom: 0;
// 	// Remove default space allocated for bullets
// 	margin-top: 0;
//     padding-left: 0;
//     margin-left: 0;
// }

// .tribe-events-category-eten,
// .tribe-events-category-natuur,
// .tribe-events-category-creatief,
// .tribe-events-category-gezondheid,
// .tribe-events-category-workshops {
//     background-color: #ff8744;
//     color: $color-white;
//     border-radius: 4px;
// }

// .tribe-events-category-creatief {
//     background-color: #0f6a74;
// }

// .tribe-events-category-eten {
//     background-color: #79a050;
// }

// .tribe-events-category-natuur {
//     background-color: #6caea2;
// }

// .tribe-events-category-gezondheid {
//     background-color: #3c7eaf;
// }

// .tribe-events-category-workshops {
//     background-color: #1ebdf7;
// }

// .tribe-events-maandag,
// .tribe-events-dinsdag,
// .tribe-events-woensdag,
// .tribe-events-donderdag,
// .tribe-events-vrijdag,
// .tribe-events-zaterdag,
// .tribe-events-zondag {
//     background: #5aa5a1;
//     color: $color-white;
// }

// // // Single - links
// // a.tribe-events-gcal, a.tribe-events-ical {
// //     float: none !important;
// // }

// // // Single - meta
// // .tribe-events-single-section.tribe-events-event-meta.primary {
// //     width: 60%;
// //     display: inline-block;
// // }

// // .tribe-events-event-meta .tribe-events-meta-group {
// //     padding: 0 2em !important;
// // }

// // .tribe-events-meta-group-details,
// // .tribe-events-meta-group-organizer {
// //     width: 50% !important;
// // }

// // .tribe-events-event-meta {
// //     border: 1px solid $color-gray-light;
// //     border-radius: $border-radius;
// //     margin-bottom: 1em;
// //     margin-top: 1em;
// // }

// // .tribe-events-event-cost {
// //     margin-bottom: 0 !important;
// // }

// // .tribe-events-tickets-title.tribe--rsvp,
// // .tribe-events-single-section-title {
// //     font-size: 1.5em !important;
// // }

// // .tribe-events-venue-map {
// //     width: 66.66%;
// //     display: inline-block;
// // }

// // .tribe-venue {
// //     font-weight: bold;
// // }

// .tribe-events-gmap {
//     margin-top: 1em;
//     text-decoration: none;
// 	background: $color-primary;
// 	color: $color-white;
// 	padding: 0.75em 2.5em;
// 	border-radius: $border-radius-round;
// 	display: inline-block;
// 	cursor: pointer;
// 	line-height: 1.4;
// 	text-transform: uppercase;
// 	letter-spacing: 2px;
//     font-weight: 600;
//     font-style: normal;
// 	font-size: 0.75em;
// }

// // // RSVP
// // .tribe-tickets-rsvp {
// //     border: 1px solid $color-gray-light;
// //     border-radius: $border-radius;
// //     padding: 1em;
// //     width: calc(40% - 1em);
// //     float: left;
// //     margin-right: 1em;
// //     margin-top: 1em;
// // }

// .tribe-button.tribe-button--rsvp {
//     text-decoration: none;
// 	background: $color-primary;
// 	color: $color-white;
// 	padding: 0.75em 2.5em;
// 	border-radius: $border-radius-round;
// 	display: inline-block;
// 	cursor: pointer;
// 	line-height: 1.4;
// 	text-transform: uppercase;
// 	letter-spacing: 2px;
//     font-weight: 600;
//     border: 0;
// 	font-size: 0.75em;
// }

// // .tribe-events-tickets {
// //     width: 100%;
// //     background: $color-white !important;;
// //     // padding: 1em;
// // }