/**
 * Implements the Sticky Footer pattern.
 *
 * On pages with less content the footer will still be positioned at the bottom
 * of the screen.
 *
 * See: http://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
 */

body {
	display: flex;
	flex-direction: column;
	// This needs the `html` tag to be set to `height: 100%`
	min-height: 100%;
}

// Make sure the content area will take up all available space in the
// container, and will be at least the height it needs for its content.
.Site-content {
	flex: 1 0 auto;
	width: 100%;
    position: relative;
    border-bottom: 1px solid #000;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
    z-index: 3;
}

.Site-language {
	position: relative;
    width: 5rem;
    height: 2rem;
    overflow: hidden;
    margin-left: 2rem;

    .menu-language-menu-container {
        position: absolute;
        left: 0;
        top: 0;
    }
	
    ul {
        // Remove bullets
        list-style: none;
        margin-bottom: 0;
        // Remove default space allocated for bullets
        margin-top: 0;
        padding-left: 0;
        align-items: center;
        display: flex;
        flex-wrap: wrap;

        li {
            margin-bottom: 0.5em;
            margin-right: 0.5em;
        }
    }
}