.Footer {
}

.Footer-pre {
    background-color: $color-gray-dark;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #000;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
    z-index: 2;
    position: relative;

    @include media-query($breakpoint-l) {
        padding-bottom: 3.5em;
        padding-top: 2.5em;
    }

    .Grid-cell p {

        @include media-query($breakpoint-l) {
            text-align: justify;
        }
    }
}

.Footer-title {
    font-weight: 600;
    margin-bottom: 1em;
}

.Footer-copy {
    background: $color-gray-dark;
    font-size: 0.8em;
    padding: 1em 0;
}
