.Sidebar {
    margin-top: 0.5em;
    padding-bottom: 2em;
}

.Sidebar-widget {
    margin-bottom: 2em;

    h3 {
        font-size: 1.5em;
        width: 100%;
    }

    ul {
        // Remove bullets
        list-style: none;
        margin-bottom: 0;
        // Remove default space allocated for bullets
        margin-top: 0;
        padding-left: 0;
    }

    li {
        margin-bottom: 0.5em;

        a {
            text-decoration: none;
            display: block;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
                color: $color-black;
                text-decoration: underline;
            }
        }
    }
}

.Sidebar-header {
    color: $color-gray-dark;
    width: 70%;
    margin: 1.5em auto 0.5em;
}

.Sidebar-image {
    width: 100%;
}
