.Button {
	text-decoration: none;
	background: $color-gray;
	color: $color-white;
	padding: 0.7em 1.5em;
	display: inline-block;
	border: 0;
	cursor: pointer;
	transition: all .2s ease-in-out;
	line-height: 1.4;
	position: relative;
	//text-transform: lowercase;
	text-align: center;
	border: 1px solid #232323;
	box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
	border-radius: $border-radius;

	&:hover,
	&:active,
	&:focus {
		background: $color-gray-dark;
		color: $color-white;
	}
}

.Button--white {
    background-color: $color-white;
	color: $color-blue;
	
	&:hover,
	&:active,
	&:focus {
		background: $color-gray-light;
		color: $color-blue;
	}
}

.Button--blue {
    background-color: $color-blue;
	
	&:hover,
	&:active,
	&:focus {
		background: $color-blue-light;
	}
}


.Button--blueLight {
    background-color: $color-blue-light;
	color: $color-white;
	
	&:hover,
	&:active,
	&:focus {
		background: $color-blue;
	}
}


.Button--withIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.Button--social {
	padding: 0.4em 0.6em;
	font-size: 1.3em;
	border-radius: 100%;
	background: $color-black;
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover,
	&:active,
	&:focus {
		background: $color-gray-dark;
	}
}

.Button--center {
	margin-left: auto;
	margin-right: auto;

}