
// On mobile there is a pure CSS dropdown menu when you click on the label/hamburger

.Nav {
    display: flex;
    align-items: center;
}

.Nav-wrapper {
    top: -70em;
    position: absolute;
    right: 0;
    transition: all .3s cubic-bezier(.17,.04,.03,.94);
    width: 100%;
    z-index: 3;
    background: $color-gray-dark;
    padding: 2em;
    border-bottom: 1px solid #000;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);

    @include media-query($breakpoint-xxl) {
        // On large screens we want to see the menu anyway
        top: 0;
        right: 0;
        position: relative;
        padding: 0;
        background: transparent;
        box-shadow: none;
        height: inherit;
        max-width: inherit;
        width: inherit;
        border-bottom: 0;
    }
}

.Nav-list {
    position: relative;
    z-index: 1;

	// Remove bullets
	list-style: none;
    margin-bottom: 0;
    
	// Remove default space allocated for bullets
	margin-top: 0;
    padding-left: 0.5em;
    text-align: center;

    @include media-query($breakpoint-xxl) {
        // On larger screens show items next to each other
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
}

.Nav-list-item {
    margin-bottom: 0.5em;
    margin-right: 0.5em;

    &:last-child {
        margin-bottom: 0;
        margin-right: 0;
    }

    @include media-query($breakpoint-xxl) {
        margin-bottom: 0;
        margin-right: 1em;
    }
}

.current-menu-item .Nav-link {
    text-decoration: none;
    padding: 0.5em 0;
    // font-size: 0.9em;
    border-bottom: 1px solid $color-black;
}

.Nav-link {
    text-decoration: none;
    padding: 0.5em 0;
    // font-size: 0.9em;
    //text-transform: lowercase;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border-bottom: 1px solid $color-black;
    }
}

.Nav-toggle {
    display: none;
}

.Nav-login {
    cursor: pointer;
    // To align the icons on the same height
    line-height: 1; 
    // To put the icons on top of the Nav-wrapper
    position: relative;
    z-index: 2;
}

.Nav-label {
    cursor: pointer;
    // To align the icons on the same height
    line-height: 1; 
    // To put the icons on top of the Nav-wrapper
    position: relative;
    z-index: 1;
}

.Nav-close {
    background: 0 0;
    cursor: pointer;
    height: 100vh;
    right: 0;
    position: absolute;
    top: 0;
    width: 0vw;
}

.Nav-closeIcon {
    position: absolute;
    right: 1em;
    top: 1em;
    cursor: pointer;

    @include media-query($breakpoint-xxl) {
        display: none;
    }
}

// Acties voor de toggle
.Nav-toggle:checked~.Nav-wrapper {
    top: 3.5em;
}

.Nav-toggle:checked~.Nav-close {
    width: 100vw;
}

.Nav-toggle:checked~.Nav-label>.Icon--menu {
    display: none;
}

.Nav-toggle:checked~.Nav-label>.Icon--close {
    display: block;
}

