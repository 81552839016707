.List {
	// Remove bullets
	list-style: none;
	margin-bottom: 0;
	// Remove default space allocated for bullets
	margin-top: 0;
	padding-left: 0;
}

.List--horizontal {
	align-items: center;
	display: flex;
	flex-wrap: wrap;

	.List-item {
		margin-bottom: 0.5em;
		margin-right: 0.5em;
	}
}

.List--center {
	justify-content: center;
}

.List--borderBottom {

	.List-item {
		border-bottom: 1px solid $color-gray;
		padding: 0.5em 0;

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			border: 0;
			padding-bottom: 0;
		}
	}
}