// .Search {
//     margin-left: 1em;
// }

// .Search-form {
//     display: none;
// }

// .Search-input {
//     display: none;
//     position: absolute;
//     right: 0;
//     width: 90%;
//     top: 0;
//     border-radius: $border-radius-round;
//     border: 0;
//     margin: 0.5em;
//     z-index: 3;
//     padding: 0.25em 0.5em;

//     @include media-query($breakpoint-xxl) {
//         right: 5em;
//     }
// }

// .Search-toggle {
//     display: none;
// }

// .Search-label {
//     cursor: pointer;
// }


// // Acties voor de toggle
// .Search-toggle:checked~.Search-form {
//     display: block;
// }

// .Search-toggle:checked~.Search-label>.Icon--search {
//     display: none;
// }

// .Search-toggle:checked~.Search-label>.Icon--close {
//     display: block;
// }

// // Zoeken in nav only
// .Search-form .search-form {
//     position: absolute;
//     right: 7.5em;
//     top: 0.25em;
//     width: calc(100% - 8em);

//     @include media-query($breakpoint-xl) {
//         z-index: 3;
//     }
// }

// .Search-form .search-field {
//     background-color: $color-white;
// }

// .search-form {
//     display: flex;
//     align-items: center;
//     width: 100%;
//     position: relative;
// }

// .search-field {
//     background-color: $color-gray-light;
//     border-radius: $border-radius-round;
//     padding: 0.5em 7em 0.5em 0.5em;
//     border: 0;
//     width: 100%;
    
//     &:hover,
// 	&:active,
// 	&:focus {
// 		outline: 0;
// 	}
// }

// .search-submit {
//     text-decoration: none;
// 	background: $color-primary;
// 	color: $color-white;
// 	padding: 0.7em 1.5em;
// 	border-radius: $border-radius-round;
// 	display: inline-block;
// 	border: 0;
// 	cursor: pointer;
// 	transition: all .2s ease-in-out;
// 	line-height: 1.4;
// 	position: relative;
// 	text-transform: uppercase;
// 	letter-spacing: 2px;
// 	font-weight: 600;	
//     font-size: 0.75em;
//     margin-left: 0.5em;
//     position: absolute ;
//     right: 0.5em;

// 	&:hover,
// 	&:active,
// 	&:focus {
// 		outline: 0;
// 		background: $color-primary-dark;
// 		color: $color-white;
// 	}
// }

.searchform {

    #s {
        width: 70%;
        padding: 0.25em 0.5em;
		background-color: #444444;
		border: 1px solid #232323;
		box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
		border-radius: $border-radius;
		color: $color-white;
    }

    #searchsubmit {
        align-self: flex-end;
		width: auto;
		text-transform: lowercase;
		text-decoration: none;
		background: $color-gray;
		color: $color-white;
		padding: 0.45em 1.5em;
		display: inline-block;
		border: 0;
		cursor: pointer;
		transition: all .2s ease-in-out;
		line-height: 1.4;
		position: relative;
		text-align: center;
		border-radius: $border-radius;
		border: 1px solid #232323;
		box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);

		&:hover,
		&:active,
		&:focus {
			outline: 0;
			background: $color-gray-dark;
			color: $color-white;
		}
    }
}