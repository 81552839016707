.InlineForm {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.InlineForm-input {
	flex: 1 0 auto;
	margin-bottom: 0.5em;
}

.InlineForm-submit {
	margin-bottom: 0.5em;
	margin-left: 1em;
}
