.u-sizeFit {
	flex-basis: auto;
	width: auto;
}

.u-sizeFill {
	flex-grow: 1;
	width: auto;
}

/**
 * Sizing utilities
 *
 * Per breakpoint available
 */

.u-size1of1 {
	width: percentage(1/1);
}

.u-size1of2 {
	width: percentage(1/2);
}

.u-size1of3 {
	width: percentage(1/3);
}

.u-size1of4 {
	width: percentage(1/4);
}

.u-size1of5 {
	width: percentage(1/5);
}

// Breakpoint M
@include media-query($breakpoint-m) {

	.u-m-size1of2 {
		width: percentage(1/2);
	}

    .u-m-size1of3 {
		width: percentage(1/3);
	}

    .u-m-size2of3 {
		width: percentage(2/3);
	}

    .u-m-size1of4 {
		width: percentage(1/4);
	}

	.u-m-size1of6 {
		width: percentage(1/6);
	}

	.u-m-size1of7 {
		width: percentage(1/7);
	}

	.u-m-size1of8 {
		width: percentage(1/8);
	}
}

@include media-query($breakpoint-ml) {

	.u-ml-size1of2 {
		width: percentage(1/2);
	}
}

// Breakpoint L
@include media-query($breakpoint-l) {

	.u-l-size1of2 {
		width: percentage(1/2);
	}

	.u-l-size1of3 {
		width: percentage(1/3);
	}

	.u-l-size2of3 {
		width: percentage(2/3);
	}

	.u-l-size1of4 {
		width: percentage(1/4);
	}

	.u-l-size1of5 {
		width: percentage(1/5);
	}

	.u-l-size2of5 {
		width: percentage(2/5);
	}

	.u-l-size3of5 {
		width: percentage(3/5);
	}

	.u-l-size3of4 {
		width: percentage(3/4);
	}
	
	.u-l-size1of8 {
		width: percentage(1/8);
	}
	
	.u-l-size1of10 {
		width: percentage(1/10);
	}

}

// Breakpoint XL
@include media-query($breakpoint-xl) {

	.u-xl-size1of1 {
		width: percentage(1/1);
	}

	.u-xl-size1of2 {
		width: percentage(1/2);
	}

	.u-xl-size1of3 {
		width: percentage(1/3);
	}

	.u-xl-size2of3 {
		width: percentage(2/3);
	}

	.u-xl-size1of4 {
		width: percentage(1/4);
	}

	.u-xl-size3of4 {
		width: percentage(3/4);
	}

	.u-xl-size1of5 {
		width: percentage(1/5);
	}

	.u-xl-size1of10 {
		width: percentage(1/10);
	}
	
	.u-xl-size1of12 {
		width: percentage(1/12);
	}
}

// Breakpoint XXL
@include media-query($breakpoint-xxl) {

	.u-xxl-size1of3 {
		width: percentage(1/3);
	}
	
	.u-xxl-size1of4 {
		width: percentage(1/4);
	}

	.u-xxl-size3of4 {
		width: percentage(3/4);
	}
}
