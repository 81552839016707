.menu {
    position: relative;

	// Remove bullets
	list-style: none;
    margin-bottom: 0;
    
	// Remove default space allocated for bullets
    margin-top: 0;
    padding: 0;
    text-align: center;

    display: flex;
    flex-wrap: wrap;
 
    .current-menu-item a {

        .menu-image {
            filter: grayscale(0%) contrast(100%);
        }

        &:after {
            background-color: rgba(0, 0, 0, 0);
            opacity: 0;
        }
    }

    > .menu-item {
        width: 50%;
        height: 35vh;
        overflow: hidden;
        display: flex;

        @include media-query($breakpoint-l) {
            width: 25%;
            height: 50vh;
        }
        
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            width: 100%;
            text-decoration: none;

            &:active,
            &:focus,
            &:hover {

                .menu-image {
                    filter: grayscale(0%) contrast(100%);
                }

                &:after {
                    background-color: rgba(0, 0, 0, 0);
                }
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.3);
                transition: all 0.3s ease-in-out;
            }
        }
    }
    
    .menu-image-hover-wrapper {
        position: absolute;
        padding-right: 0 !important;
        width: 100%;
        padding-bottom: 56.3%;
        height: 0;
        overflow: hidden;
    }

    .hovered-image {
        margin: 0 !important;
        filter: none;
    }

    .hovered-image,
    .menu-image {
        padding-right: 1px !important;
        padding-bottom: 1px !important;
        min-height: 100%;
        width: 100%;
        height: auto;
        // min-height: 1px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: grayscale(100%) contrast(125%);
        object-fit: cover;
        @include object-fit(cover);
        transition: all 0.3s ease-in-out;	
    }
    
    .menu-image-title {
        z-index: 2;
        font-size: 2em;
        font-weight: 600;
        text-shadow: 0px 1px 2px black;
    }

    .sub-menu {
        padding: 0;
    }
}

#menu-cv-menu-en,
#menu-cv-menu-it,
#menu-cv-menu-nl {
    > .menu-item {
        width: 100%;
        height: 15rem;
        overflow: hidden;
        display: flex;
        @include media-query($breakpoint-m) {
            width: 50%;
            height: 20rem;
        }
        @include media-query($breakpoint-xxl) {
            width: 33.33%;
            height: 30rem;
        }
        @include media-query($breakpoint-xxxl) {
            width: 20%;
            height: 30rem;
        }
    }
}

.home {
    #menu-cv-menu-en,
    #menu-cv-menu-it,
    #menu-cv-menu-nl {
        display: none;
    }
}