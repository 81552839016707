.Panel {
    background: $color-white;
    padding: 1em 2em;
    display: flex;
    flex-grow: 1;
}

.Panel--primary {
    background: $color-primary;
    color: $color-white;
    justify-content: flex-end;
}

.Panel--gray {
    background: $color-gray-light;
    padding: 1em;
    display: block;
}